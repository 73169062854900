<template>
    <modal :name="modal_name" transition="nice-modal-fade" class="final-modal">
        <div class="v-modal-content">
            <div class="v-modal-header">
                <span class="v-modal-dialog-title v-modal-title">SHOW QR CODES</span>
            </div>
            <div class="v-modal-body overflow-popup" id="printId">
                <div class="d-flex flex-wrap">
                    <div v-for="(code,index) in qr_codes" :key="index">
                        <div class="qrcode">
                            <qr-code
                                class="bcode"
                                :text="code.qr_code_url"
                                :size="180"
                                color="#005dae"
                            ></qr-code>
                        </div>
                        <div class="contentCenter width-fit mt-4">
                            <span class="fw-500 fs-18">{{code.table_id}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="v-modal-footer modal-bg text-center pt-5 pb-5">
                <a class="btn btn-secondary btn-smm text-uppercase ml-1" @click="hideShowQrCode">CLOSE</a>
                <a class="btn btn-success btn-smm text-uppercase ml-1" @click="printWeb()">PRINT</a>
            </div>
        </div>
    </modal>
</template>
<script>
import print from "./mixins/print.js"
export default {
    props:['modal_name','qr_codes'],
    data(){
        return{

        }
    },
    mixins:[print],
    methods:{
        hideShowQrCode(){
            this.$modal.hide(this.modal_name)
            this.$emit("hideShowQrCode")
        },
        printQrCode(){

        }
    }
}
</script>
<style scoped>
    .width-fit{
        width: max-content;
    }
    .qrcode{
        background-color: #f5f6f9;
        padding: 15px;
        width: max-content;
    }
    .contentCenter{
        margin: 0 auto;
    }
    .fs-18{
        font-size: 18px;
    }
    .overflow-popup{
        max-height: 400px;
        overflow: auto;
    }
</style>